@import '../../../assets/scss/theme.scss';

.confirm-gray-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 1rem !important;
  letter-spacing: 0.113rem;
  color: $blk-00 !important;
  background-color: $gry-09 !important;
  border: 0rem solid $gry-09 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-top: 2.5rem !important;
  margin-bottom: 3.125rem !important;
}

.confirm-red-status-bg {
  font-family: 'Poppins';
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 1rem !important;
  letter-spacing: 0.113rem;
  color: $red-01 !important;
  background-color: $red-02 !important;
  border: 0rem solid $grn-03 !important;
  border-radius: 0rem !important;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 0.688rem !important;
  padding-right: 0.688rem !important;
  width: 100%;
  text-align: center;
  align-items: unset;
  margin-top: 2.5rem !important;
  margin-bottom: 3.125rem !important;
}
