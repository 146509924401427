@import '../../../assets/scss/theme.scss';

.glisten-modal-body {
  @media only screen and (max-width: 992px) {
    margin-top: 2rem !important;
    padding-left: 1.375rem !important;
    padding-right: 1.375rem !important;
  }
  padding-left: 4.375rem;
  padding-right: 4.375rem;
  padding-bottom: 0 !important;
}

.glisten-textfield-label {
  font-family: 'Avenir-Roman';
  font-size: 0.6875rem;
  letter-spacing: 1.24px;
  color: $gry-02;
  padding-top: 0.688rem;
  padding-left: 0.688rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.glisten-textfield {
  border: 0 !important;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid $blk-00 !important;
  border-radius: 0 !important;
  font-family: 'Avenir-Roman';
  font-size: 0.875rem !important;
  letter-spacing: 1.58px;
  color: $blk-00 !important;
  padding-top: 0.375rem !important;
  padding-left: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.glisten-textfield-label-hidden {
  visibility: hidden;
}

.group-title {
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 0.813rem;
  letter-spacing: 0.091rem;
  line-height: 1.188rem;
  color: $blk-00;
  padding-left: 1.25rem !important;
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.add-cust-top {
  background-color: $wht-00 !important;
  border: none !important;
  padding: 0 !important;
}

.accordion-collapse {
  border: solid $blu-01 !important;
  border-width: 0 !important;
}

.accordion-button::after {
  width: 23px !important;
  height: 23px !important;
  margin-right: 1.688rem !important;
  background-image: url('../../../assets/images/ic-plus.svg') !important;
  background-size: 23px !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('../../../assets/images/ic-minus.svg') !important;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.accordion-body {
  padding-top: 0rem !important;
}

.accordion-button {
  border-radius: 0rem !important;
}

.prim-btn-sm {
  font-family: 'Poppins';
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 0.688rem !important;
  letter-spacing: 0.078rem;
  color: $wht-00 !important;
  background-color: $blk-00 !important;
  border: none !important;
  border-radius: 0 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.563rem !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  width: 90% !important;
  align-self: center;
}
